/**
 * Bootstrap 4 Inits + Handlers
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {


    $('input[type=radio][name=enquiry_type]').change(function(evt) {
        $('.form-wrapper').removeClass('d-none');
    });

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('.close-modal').on('click', function(e) {
        e.preventDefault();
        $(this).parent().find('.form-messages').removeClass('success error').addClass('d-none').text('');
    });

    $('.modal').on('hidden.bs.modal', function() {
        $(this).find('.form-messages').removeClass('success error').addClass('d-none').text('');
    });
});
